<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i> Actualiser
        </button>
      </div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'employees-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">CIN</th>
          <th scope="col">Phone</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(employee, index) in employees" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ employee.reference }}
          </td>
          <td>
            {{ employee.fullName }}
          </td>
          <td>{{ employee.cin }}</td>
          <td class="text-warning">{{ employee.phone }}</td>
          <td>__</td>

          <td class="text-danger">
            <button
              @click="
                $router.push({
                  name: 'users-create',
                  query: {
                    employeeReference: employee.reference,
                  },
                })
              "
            >
              Access
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("employee", {
      employees: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("employee/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("employee/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("employee/getAll");
    },

    async listEmployees(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("employee/getTodays");
      } else {
        return await this.$store.dispatch("employee/getEmployees");
      }
    },
    async sort(value) {
      return await this.$store.commit("employee/sort", value);
    },
  },
};
</script>
